<!-- @Author: Yu_Bo -->
<!-- <point-out ref='dialogTip' dialogTitle="温馨提示" dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='false' :affirmShow='true'>
  <div class="zc_dialog_tip_content">
    <div class="text">请先下载PC客户端，完成安装后再体验，客户端目前仅支持windows版本哦～</div>
  </div>
</point-out> -->
<template>
    <div class="point_out zc_dialog_tip">
        <el-dialog custom-class="zc_dialog_tip" :top="type=='session'?'36vh':'15vh'" :style="[allStyle]" :show-close="type=='session'?false:true" :visible.sync="dialogVisible" :width="dialogWidth" :close-on-press-escape="false" :close-on-click-modal='false' append-to-body>
            <div class="title" slot="title">
                <i v-if="iconShow" class="el-icon-warning" :style="[iconStyle]"></i>
                <div class="name">{{ dialogTitle }}</div>
            </div>
            <slot></slot>
            <span slot="footer" class="dialog-footer">
                <el-button v-if="cancelShow" class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
                <el-button v-if="affirmShow" class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn">{{type=='onload'?'立即下载':'确 定'}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        type: {//类型
            type: String,
            defaul: ''
        },
        dialogTitle: {//标题
            type: String,
            defaul: '提示'
        },
        dialogWidth: {//宽度
            type: String,
            defaul: '30%'
        },
        iconShow: {//图标是否显示
            type: Boolean,
            defaul: false
        },
        iconColor: {//图标颜色
            type: String,
            defaul: '#333'
        },
        borderRadius: {//圆角
            type: String,
            defaul: '2px'
        },
        cancelShow: {//取消按钮是否显示
            type: Boolean,
            defaul: true
        },
        affirmShow: {//确认按钮是否显示
            type: Boolean,
            defaul: true
        },
        affirmText: {
            type: String,
            defaul: '确 定'
        },
    },
    data() {
        return {
            dialogVisible: false,
        }
    },
    computed: {
        allStyle() {
            return {
                '--borderRadius': this.borderRadius
            }
        },
        iconStyle() {
            return {
                color: this.iconColor
            }
        },
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn() {
            this.dialogVisible = true
        },
        // 取消
        cancelBtn() {
            this.dialogVisible = false
        },
        // 确定
        affirmBtn() {
            this.dialogVisible = false
            // 确定之后需要处理的
            if (this.type == 'pay') {
                this.$emit('close')
            }
            // 个人中心账号管理
            if (this.type == 'account') {
                this.$emit('close')
            }
            // 权限管理删除
            if (this.type == 'qxDel') {
                this.$emit('close')
            }
            // 成员管理删除
            if (this.type == 'cyDel') {
                this.$emit('close')
            }
            // 退出登录
            if (this.type == 'quit') {
                this.$emit('close')
            }
            // 删除
            if (this.type == 'works') {
                this.$emit('close')
            }
            // 分身删除
            if (this.type == 'modelDel') {
                this.$emit('close')
            }
            // 台本删除
            if (this.type == 'book') {
                this.$emit('close')
            }
            // 客服
            if (this.type == 'session') {
                this.$emit('confirm')
            }
        },
    },
}
</script>

<style lang='scss' scoped>
::v-deep .el-dialog {
    border-radius: var(--borderRadius);
}
.point_out,
.zc_dialog_tip {
    .title {
        display: flex;
        align-items: center;

        .el-icon-warning {
            font-size: 24px;
        }

        .name {
            padding-left: 10px;
            font-size: 16px;
            margin-top: -3px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }
    }
}
</style>
