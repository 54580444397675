export const arr_list = [
  {
    id:1,
    key:'home_index',
    path:'/home/index',
    title:"首页",
    iconPath:require('@/assets/images/nav/nav_icon_a.png'),
    selectedIconPath:require('@/assets/images/nav/nav_icon_aHL.png'),
    childer:[],
  },
  {
    id:2,
    key:'workben_index',
    path:'/workben/index',
    title:"工作台",
    iconPath:require('@/assets/images/nav/nav_icon_b.png'),
    selectedIconPath:require('@/assets/images/nav/nav_icon_bHL.png'),
    childer:[],
  },
  {
    id:3,
    key:'digital_index',
    path:'/digital/index',
    title:"数字市场",
    iconPath:require('@/assets/images/nav/nav_icon_c.png'),
    selectedIconPath:require('@/assets/images/nav/nav_icon_cHL.png'),
    childer:[],
  },
  {
    id:4,
    key:'creation_index',
    path:'/creation/index',
    title:"作品管理",
    iconPath:require('@/assets/images/nav/nav_icon_d.png'),
    selectedIconPath:require('@/assets/images/nav/nav_icon_dHL.png'),
    childer:[
      {
        id:567,
        key:'creation_video',
        parentKey:'creation_index',
        path:'/creation/videoWorks',
        title:"视频作品",
        iconPath:require('@/assets/images/nav/creation_icon_a.png')
      },
      {
        id:568,
        key:'creation_audio',
        parentKey:'creation_index',
        path:'/creation/audioWorks',
        title:"音频作品",
        iconPath:require('@/assets/images/nav/creation_icon_b.png')
      },
      {
        id:569,
        key:'creation_draw',
        parentKey:'creation_index',
        path:'/creation/drawWorks',
        title:"绘画作品",
        iconPath:require('@/assets/images/nav/creation_icon_c.png')
      }
    ],
  },
  {
    id:5,
    key:'person_index',
    path:'/person/index',
    title:"我的",
    iconPath:require('@/assets/images/nav/nav_icon_e.png'),
    selectedIconPath:require('@/assets/images/nav/nav_icon_eHL.png'),
    childer:[
      {
        id:50,
        key:'person_customer',
        parentKey:'person_index',
        path:'/person/customer',
        title:"客户管理",
        iconPath:require('@/assets/images/nav/person_icon_f.png')
      },
      {
        id:51,
        key:'person_order',
        parentKey:'person_index',
        path:'/person/order',
        title:"我的订单",
        iconPath:require('@/assets/images/nav/person_icon_a.png')
      },
      {
        id:52,
        key:'person_workConsumption',
        parentKey:'person_index',
        path:'/person/workConsumption',
        title:"作品消耗",
        iconPath:require('@/assets/images/nav/person_icon_b.png')
      },
      {
        id:53,
        key:'person_accountManage',
        parentKey:'person_index',
        path:'/person/accountManage',
        title:"账号管理",
        iconPath:require('@/assets/images/nav/person_icon_c.png')
      },
      {
        id:55,
        key:'person_permission',
        parentKey:'person_index',
        path:'/person/permission',
        title:"权限管理",
        iconPath:require('@/assets/images/nav/person_icon_d.png')
      },
      {
        id:57,
        key:'person_message',
        parentKey:'person_index',
        path:'/person/message',
        title:"消息中心",
        iconPath:require('@/assets/images/nav/person_icon_e.png')
      }
    ],
  },
]
