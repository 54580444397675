import personApi from '@/axiosApi/person'
import {
  getInfoConfig
} from '@/utils/auth'
const userInfo = {
  namespaced: true,
  state: {// 储存的数据
    user_info: JSON.parse(localStorage.getItem('user_info')) || '',
    config: JSON.parse(localStorage.getItem('config')) || '',
  },
  mutations: { // 修改
    // 储存用户信息
    SET_USER_INFO(state, info) {
     	state.user_info = JSON.parse(info)
      localStorage.user_info = info
    },
    // 清除用户信息
    DEL_USER_INFO(state, info) {
      state.user_info = ''
      localStorage.removeItem('user_info')
    },
    SET_CONFIG: (state, config) => {
      state.config = JSON.parse(config)
      localStorage.config = config
    },
  },
  actions: { // 发起异步请求
    getUserInfo({ commit }) {
    	personApi.accountBasicInfo().then(res => {
    		if (res.code == 1000) {
    			return commit('SET_USER_INFO', JSON.stringify(res.result))
    		}
    	})
    },
    getConfig({ commit }) {
    	personApi.getMemberConfig().then(res => {
    		if (res.code == 1000) {
    			return commit('SET_CONFIG', JSON.stringify(res.result))
    		}
    	})
    },
  },

};
export default userInfo;
