// 工作台api
import axios from '../request/request.js'
//
export default {
  // 数字孪生参数
  getModelParams(data) {
    return axios({
      url: "/member/model_list/params",
      method: "get",
      data,
    });
  },
  // 扣减次数下单
  directModel(data) {
    return axios({
      url: "/member/model_list/direct_model",
      method: "post",
      data,
    });
  },
  // 不扣减次数下单
  unDirectModel(data) {
    return axios({
      url: "/member/model_list/create_order",
      method: "post",
      data,
    });
  },
  // 不扣减次数支付
  payModel(data) {
    return axios({
      url: "/member/model_list/pay_order",
      method: "post",
      data,
    });
  },
  // 声音克隆价格
  audioPrice(data) {
    return axios({
      url: "/member/tts_list/params",
      method: "get",
      data,
    });
  },
  // 生成克隆语音订单
  getAudioOrder(data) {
    return axios({
      url: "/member/tts_list/order",
      method: "post",
      data,
    });
  },
  // 声音克隆钻石支付
  audioDiamondPay(data) {
    return axios({
      url: "/member/tts_list/diamond_pay",
      method: "post",
      data,
    });
  },
  // 声音克隆兑换码支付
  audioCodePay(data) {
    return axios({
      url: "/member/tts_list/code_pay",
      method: "post",
      data,
    });
  },
  // 链接转台本
  shareUrl(data) {
    return axios({
      url: "/member/member_script/share_url",
      method: "post",
      data,
    });
  },
  // 台本分类
  catList(data) {
    return axios({
      url: "/member/member_script/cat_list",
      method: "get",
      data,
    });
  },
  // 台本专辑列表
  albumList(data) {
    return axios({
      url: "/member/member_script/album_list",
      method: "get",
      data,
    });
  },
  // 新建台本
  addScript(data) {
    return axios({
      url: "/member/member_script",
      method: "post",
      data,
    });
  },
  // 音频转文本
  asrConvert(data) {
    return axios({
      url: "/member/member_script/asr_convert",
      method: "post",
      data,
      'Headers': { 'Content-Type': 'multipart/form-data' }
    });
  },
  // 分身筛选项
  listScenceParams(data) {
    return axios({
      url: "/member/list_scence/params",
      method: "get",
      data,
    });
  },
  // 列表（免费、vip）
  marketModelList(data) {
    return axios({
      url: "/member/market/model_list",
      method: "get",
      data,
    });
  },
  // 定制模特列表
  getMyModel(data) {
    return axios({
      url: "/member/my_model",
      method: "get",
      data,
    });
  },
  // 最近使用模特列表
  modelRecently(data) {
    return axios({
      url: "/member/model_list/model_recently",
      method: "get",
      data,
    });
  },
  // 分身
  modelListScence(data) {
    return axios({
      url: "/member/model_list_scence",
      method: "get",
      data,
    });
  },
  // 我的模板=筛选条件
  templateParams(data) {
    return axios({
      url: "/member/member_template/params",
      method: "get",
      data,
    });
  },
  // 我的模板=最近使用
  recentUse(data) {
    return axios({
      url: "/member/member_template/recent_use",
      method: "get",
      data,
    });
  },
  // 我的模板=已购、定制
  templateIndex(data) {
    return axios({
      url: "/member/member_template/template_index",
      method: "get",
      data,
    });
  },
  // 我的模板=免费
  freeIndex(data) {
    return axios({
      url: "/member/member_template/free_index",
      method: "get",
      data,
    });
  },
  // 我的数字资产-我的声音(最近使用、男声、女声、童声)
  myVoice(data) {
    return axios({
      url: "/member/tts_create/my_voice",
      method: "get",
      data,
    });
  },
  // 我的数字资产-我的声音(已购)
  memberTtsIndex(data) {
    return axios({
      url: "/member/member_tts/index",
      method: "get",
      data,
    });
  },
  // 我的数字资产-我的声音(定制)
  ttsListIndex(data) {
    return axios({
      url: "/member/tts_list/index",
      method: "get",
      data,
    });
  },
  // 我的数字资产-我的台本获取导航分类
  bookParams(data) {
    return axios({
      url: "/member/member_script/params",
      method: "get",
      data,
    });
  },
  // 我的数字资产-我的台本获取下级分类
  bookCatList(data) {
    return axios({
      url: "/member/member_script/cat_list",
      method: "get",
      data,
    });
  },
  // 我的数字资产-我的台本获取台本
  memberScript(data) {
    return axios({
      url: "/member/member_script",
      method: "get",
      data,
    });
  },
  // 我的数字资产-我的台本删除
  delMemberScript(val) {
    return axios({
      url: "/member/member_script/" + val,
      method: "delete",
    });
  },
  // 我的数字资产-我的台本视频转台本
  videoConvert(data) {
    return axios({
      url: "/member/member_script/video_convert",
      method: "post",
      data,
      'Headers': { 'Content-Type': 'multipart/form-data' }

    });
  },
  // 我的数字资产-我的台本分享转文本
  shareUrl(data) {
    return axios({
      url: "/member/member_script/share_url",
      method: "post",
      data,
    });
  },
  // 最近作品-视频列表
  worktableIndex(data) {
    return axios({
      url: "/member/video/worktable_index",
      method: "get",
      data,
    });
  },
  // 台本===详情
  convertToTextDetail(data) {
    return axios({
      url: "/member/member_script/" + data,
      method: "get",
    });
  },
  // 台本===编辑
  editConvertToText(data, id) {
    return axios({
      url: "/member/member_script/" + id,
      method: "put",
      data,
    });
  },
  // 台本===txt、word、pdf、ppt转文本
  filesConvertToText(data) {
    return axios({
      url: "/member/member_script/read_convert",
      method: "post",
      data,
      'Headers': { 'Content-Type': 'multipart/form-data' },
    });
  },
  // 台本市场-获取导航分类
  marketScriptParams(data) {
    return axios({
      url: "/member/market_script/params",
      method: "get",
      data,
    });
  },
  // 台本市场-列表
  marketScript(data) {
    return axios({
      url: "/member/market_script",
      method: "get",
      data,
    });
  },
  // 台本市场-台本、专辑详情
  marketDetails(data) {
    return axios({
      url: "/member/market_script/" + data,
      method: "get",
    });
  },
  // 台本市场-创建订单
  marketScriptAddOrder(data) {
    return axios({
      url: "/member/market_script/add_order",
      method: "post",
      data,
    });
  },
  // 台本市场-支付订单
  marketScriptBuy(data) {
    return axios({
      url: "/member/market_script/buy",
      method: "post",
      data,
    });
  },
  // 台本专辑===新增
  addConvertAlbum(data) {
    return axios({
      url: "/member/member_script/add_album",
      method: "post",
      data,
    });
  },
  // 台本专辑===修改
  updateConvertAlbum(data) {
    return axios({
      url: "/member/member_script/update_album",
      method: "post",
      data,
    });
  },
  // 编辑模板=详情
  myModelDetails(data) {
    return axios({
      url: "/member/my_model/" + data,
      method: "get",
    });
  },
  // 编辑模板=修改保存
  myModelPut(data, vid) {
    return axios({
      url: "/member/my_model/" + vid,
      method: "put",
      data,
    });
  },
  // 编辑声音=详情
  ttsListDetails(vid) {
    return axios({
      url: "/member/tts_list/" + vid,
      method: "get",
    });
  },
  // 编辑声音=编辑
  ttsListPut(data, vid) {
    return axios({
      url: "/member/tts_list/" + vid,
      method: "put",
      data,
    });
  },
  // 创作视频=我的素材
  self_material(data) {
    return axios({
      url: "/member/video/self_material",
      method: "get",
      data,
    });
  },
  // 创作视频=上传我的素材
  up_material(data) {
    return axios({
      url: "/member/video/up_material",
      method: "post",
      data,
      'Headers': { 'Content-Type': 'multipart/form-data' }

    });
  },
  // 创作视频=删除我的素材
  self_material_del(data) {
    return axios({
      url: "/member/video/self_material_del",
      method: "post",
      data,
    });
  },
  // 创作视频=背景分类
  backgroup_cate(data) {
    return axios({
      url: "/member/video/backgroup_cate",
      method: "get",
      data,
    });
  },
  // 创作视频=背景列表
  backgroup_list(data) {
    return axios({
      url: "/member/video/backgroup",
      method: "get",
      data,
    });
  },
  // 创作视频=图片分类
  image_cate(data) {
    return axios({
      url: "/member/video/image_cate",
      method: "get",
      data,
    });
  },
  // 创作视频=图片列表
  image_list(data) {
    return axios({
      url: "/member/video/image",
      method: "get",
      data,
    });
  },
  // 创作视频=音乐分类
  music_cate(data) {
    return axios({
      url: "/member/video/music_cate",
      method: "get",
      data,
    });
  },
  // 创作视频=音乐列表
  music_list(data) {
    return axios({
      url: "/member/video/music",
      method: "get",
      data,
    });
  },
  // 创作视频=视频分类
  video_cate(data) {
    return axios({
      url: "/member/video/video_cate",
      method: "get",
      data,
    });
  },
  // 创作视频=视频列表
  video_list(data) {
    return axios({
      url: "/member/video/video",
      method: "get",
      data,
    });
  },
  // 创作视频=字幕分类
  caption_cate(data) {
    return axios({
      url: "/member/video/caption_cate",
      method: "get",
      data,
    });
  },
  // 创作视频=字幕列表
  caption_list(data) {
    return axios({
      url: "/member/video/caption",
      method: "get",
      data,
    });
  },
  // 创作视频=滤镜分类
  filter_cate(data) {
    return axios({
      url: "/member/video/filter_cate",
      method: "get",
      data,
    });
  },
  // 创作视频=滤镜列表
  filter_list(data) {
    return axios({
      url: "/member/video/filter",
      method: "get",
      data,
    });
  },
  // 创作视频=文本分类
  text_cate(data) {
    return axios({
      url: "/member/video/text_cate",
      method: "get",
      data,
    });
  },
  // 创作视频=文本列表
  text_list(data) {
    return axios({
      url: "/member/video/text",
      method: "get",
      data,
    });
  },

  // 创作视频左侧=ppt/word列表
  get_ppt_word_list(data) {
    return axios({
      url: "/member/video_data/index",
      method: "get",
      data,
    });
  },
  // 创作视频左侧=上传ppt/word文件
  upload_ppt_word(data) {
    return axios({
      url: "/member/video_data/up_data",
      method: "post",
      data,
      'Headers': { 'Content-Type': 'multipart/form-data' }
    });
  },
  // 创作视频左侧=模板主题分类
  videoThemeType(data) {
    return axios({
      url: "/member/video/theme_type",
      method: "get",
      data
    });
  },
  // 创作视频左侧=模板推荐列表
  videoTemplateIndex(data) {
    return axios({
      url: "/member/video/template_index",
      method: "get",
      data
    });
  },
  // 创作视频左侧=模板我的列表
  videoTemplateMine(data) {
    return axios({
      url: "/member/video/template_myself",
      method: "get",
      data
    });
  },
  // 创作视频左侧=模特推荐列表
  videoModelIndex(data) {
    return axios({
      url: "/member/market/model",
      method: "get",
      data
    });
  },
  // 创作视频左侧=模特最近使用列表
  videoModelRecently(data) {
    return axios({
      url: "/member/video/model_recently",
      method: "get",
      data
    });
  },
  // 创作视频左侧=模特我的列表
  videoModelMine(data) {
    return axios({
      url: "/member/video/model_list",
      method: "get",
      data
    });
  },
}
