// 充值api
import axios from '../request/request.js'
//
export default {
  // 立即充值切换数据
  getImmediately(data) {
    return axios({
      url: "/member/recharge/immediately",
      method: "get",
      data,
    });
  },
  // 立即充值切换数据
  postOrder(data) {
    return axios({
      url: "/member/consume/order",
      method: "post",
      data,
    });
  },
  // 立即支付（钻石）
  diamondPay(data) {
    return axios({
      url: "/member/consume/diamond_pay",
      method: "post",
      data,
    });
  },
  // 立即支付（兑换码）
  codePay(data) {
    return axios({
      url: "/member/consume/code_pay",
      method: "post",
      data,
    });
  },
  // 立即购买钻石（用钱 ）(下单）
  rechargeOrder(data) {
    return axios({
      url: "/member/recharge/order",
      method: "post",
      data,
    });
  },
  // 轮询查询订单状态
  checkOrderStatus(data) {
    return axios({
      url: "/member/recharge/check_order_status",
      method: "get",
      data,
    });
  },
  // 会员套餐列表
  rechargeVipList(data) {
    return axios({
      url: "/member/vip/recharge_vip_list",
      method: "get",
      data,
    });
  },
  // 创建（微信/支付宝）会员订阅订单
  createRechargeOrder(data) {
    return axios({
      url: "/member/vip/create_recharge_order",
      method: "post",
      data,
    });
  },
  // （微信/支付宝）订单生成二维码
  createQrcode(data) {
    return axios({
      url: "/member/vip/create_qrcode",
      method: "post",
      data,
    });
  },
  // （微信/支付宝）订单生成二维码
  queryOrder(data) {
    return axios({
      url: "/member/vip/query_order",
      method: "get",
      data,
    });
  },
  // 创建（钻石支付）会员订阅订单
  createConsumeOrder(data) {
    return axios({
      url: "/member/vip/create_consume_order",
      method: "post",
      data,
    });
  },
  // 会员订阅订单-钻石支付
  vipDiamondPay(data) {
    return axios({
      url: "/member/vip/diamond_pay",
      method: "post",
      data,
    });
  },
  // 会员兑换码
  exchangeMemberCode(data) {
    return axios({
      url: "/member/vip/exchange_member_code",
      method: "post",
      data,
    });
  },
}
