<!-- @Author: Yu_Bo -->
<template>
    <div>
        <div class='header_main' :class="bgShow == 6 ? 'header_main_grey' : ''">
            <div class="header_left">
                <el-tabs v-model="tabKey" type="card" @tab-click='tabClickBtn' @tab-remove="removeTab">
                    <el-tab-pane v-for="(item, index) in tabList" :closable="index ? true : false" :key='item.key'
                        :label="item.name" :name="item.key">
                        <el-tooltip effect="dark" slot="label" :content="item.name" placement="bottom">
                            <span class="header_name one-txt-cut">{{ item.name }}</span>
                        </el-tooltip>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="header_right">
                <!-- 加入云川会员 -->
                <div class="right_vip">
                    <el-popover popper-class='popovre_text_two' placement="bottom" width="240" trigger="hover"
                        :visible-arrow='false'>
                        <div class="vip_main">
                            <div class="title">加入云川会员可享受</div>
                            <template v-for="(item, index) in draw_list">
                                <div class="vip_text" :key="index" v-if="item.title">
                                    <img :src="item.image" alt="">
                                    <div class="text">
                                        <div class="t_title">{{ item.title }}</div>
                                        <div class="t_tip">{{ item.tip }}</div>
                                    </div>
                                </div>
                            </template>
                            <div class="vip_btn">
                                <el-button class="btnBgColor_blue" type="primary" @click="upgradeNowBtn">立即升级</el-button>
                            </div>
                        </div>
                        <img slot="reference" src="@/assets/images/img/vip_img.png" alt="">
                    </el-popover>
                </div>
                <div class="right_icon">
                    <!-- 问号 -->
                    <img v-if="bgShow == 6" src="../../assets/images/img/wen_img_b.png" alt="">
                    <img v-else src="../../assets/images/img/wen_img_a.png" alt="">
                    <!-- 消息 -->
                    <!-- <img v-if="bgShow == 6" src="../../assets/images/img/bell_img_b.png" alt="">
                    <img v-else src="../../assets/images/img/bell_img_a.png" alt=""> -->
                    <!-- 消息中心 -->
                    <img src="../../assets/images/img/bell_img_a.png" alt="" v-if="no_read_num == 0">
                    <div class="message" @click="toMessage" v-else>
                        <el-badge class="item" :value="no_read_num" :max="99" :hidden="no_read_num == 0">
                            <img class="img1" src="../../assets/images/img/bell_img_a.png" alt="" @click="toMessage">
                            <img class="img2" src="../../assets/images/img/bell_img_b.png" alt="" @click="toMessage">
                        </el-badge>
                    </div>
                </div>
                <div class="right_xian" :class="bgShow == 6 ? 'right_xian_grey' : ''"></div>
                <!-- 头像 -->
                <div class="right_header">
                    <el-popover popper-class='popovre_text_three' placement="bottom-end" width="280" :offset='20'
                        trigger="hover">
                        <div class="acatar_main">
                            <div class="main_info">
                                <div class="info_img">
                                    <el-avatar :size="50" :src="headPic"></el-avatar>
                                    <img class="img_bg" v-if="userInfo.is_vip"
                                        src="@/assets/images/person/accountManage/avatar_bg.png" alt="">
                                </div>
                                <div class="info_txt">
                                    <div class="t_name">{{ userInfo.nickname }}</div>
                                    <div class="t_phone">账号: {{ userInfo.mobile }}</div>
                                </div>
                            </div>
                            <!-- 已有会员 -->
                            <div class="main_vip_show" @click="upgradeNowBtn" v-if="userInfo.is_vip">
                                <img class="icon" src="../../assets/images/icon/vip_icon.png" alt="">
                                <div class="vip_show_txt">
                                    <div class="t_name">{{ userInfo.subscribe_name }}</div>
                                    <div class="t_time">
                                        <span class="time">{{ userInfo.subscribe_ended }}到期</span>
                                        <span class="btn">续费与开通</span>
                                    </div>
                                    <div class="t_day">
                                        <span class="time">您已享受会员权益{{ userInfo.subscribe_day }}天</span>
                                        <span class="btn">更多权益<i class="el-icon-arrow-right"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- 无会员 -->
                            <div class="main_vip" @click="upgradeNowBtn" v-else>
                                <div class="vip_left">
                                    <img src="../../assets/images/icon/vip_icon.png" alt="">
                                    <span>开通VIP会员</span>
                                </div>
                                <div class="vip_right">立即订阅<i class="el-icon-arrow-right"></i></div>
                            </div>
                            <div class="main_nav" v-for="(item, index) in topNav" :key="index"
                                @click="topNavBtn(item.path)">
                                <div class="nav_left">
                                    <img :src="item.img" alt="">
                                    <span>{{ item.name }}</span>
                                </div>
                                <div class="nav_right"><i class="el-icon-arrow-right"></i></div>
                            </div>
                            <div class="main_btn">
                                <span @click="quitBtn">退出登录</span>
                            </div>
                        </div>
                        <div class="avatar_img" slot="reference">
                            <el-avatar :size="30" :src="headPic"></el-avatar>
                            <img class="img_bg" v-if="userInfo.is_vip"
                                src="@/assets/images/person/accountManage/avatar_bg.png" alt="">
                        </div>
                    </el-popover>
                </div>
            </div>
        </div>
        <!-- 立即升级 -->
        <open-member ref='dialogMember'></open-member>
        <!-- 账户余额 -->
        <account-balance ref='dialogAcc' @renovate='renovateBtn'></account-balance>
        <!--  -->
        <point-out ref='dialogTip' @close='closeBtn' type='quit' dialogTitle="提示" dialogWidth='380px' :iconShow='true'
            iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">确定退出登录吗?</div>
            </div>
        </point-out>
    </div>
</template>

<script>
import { mapState } from "vuex"
import OpenMember from '@/components/invest_money/open_member.vue'//开通会员
import AccountBalance from '@/components/account_balance/account_balance.vue'//账户余额
import PointOut from '@/components/point_out/point_out.vue'//温馨提示弹框
export default {
    components: { OpenMember, AccountBalance, PointOut },
    props: {},
    data() {
        return {
            circleUrl: require('@/assets/images/img/header.png'),
            vipObj: '',
            topNav: [
                { img: require('@/assets/images/icon/avatar_icon1.png'), name: '我的订单', path: '/person/order' },
                { img: require('@/assets/images/icon/avatar_icon2.png'), name: '我的权益', path: '/person/accountManage' },
                { img: require('@/assets/images/icon/avatar_icon3.png'), name: '账号管理', path: '/person/accountManage' },
                { img: require('@/assets/images/icon/avatar_icon4.png'), name: '钻石充值', path: '' },
            ],
        }
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.userInfo.user_info,
            no_read_num: (state) => state.userInfo.config.no_read_num,// 消息
        }),
        ...mapState(['tabKey', 'tabList']),
        tabKey: {
            get() {
                return this.$store.state.tabsArr.tabKey
            },
            set(val) {
                this.$store.state.tabsArr.tabKey = val
            }
        },
        tabList: {
            get() {
                return this.$store.state.tabsArr.tabList
            },
            set(val) {
                this.$store.state.tabsArr.tabList = val
            }
        },
        // 6 灰色背景  //  图片背景
        bgShow() {
            return this.$store.state.bgShow
        },
        headPic() {
            return this.$store.state.userInfo.user_info.head_pic || this.circleUrl
        },
        draw_list() {
            if (this.vipObj) {
                return [
                    { image: require('@/assets/images/icon/draw_icon1.png'), title: '数字人使用权', tip: '会员专属数字人' },
                    { image: require('@/assets/images/icon/draw_icon3.png'), title: '视频制作时长', tip: this.vipObj.video.unit_text + '/' + this.vipObj.video.card_type_text },
                    { image: require('@/assets/images/icon/draw_icon4.png'), title: '绘画生成次数', tip: this.vipObj.draw.unit_text + '/' + this.vipObj.draw.card_type_text },
                    { image: require('@/assets/images/icon/draw_icon6.png'), title: '漫画生成次数', tip: this.vipObj.cartoon.unit_text + '/' + this.vipObj.cartoon.card_type_text },
                    { image: require('@/assets/images/icon/draw_icon5.png'), title: '音频制作时长', tip: this.vipObj.audio.unit_text + '/' + this.vipObj.audio.card_type_text },
                ]
            }
        }
    },
    watch: {},
    created() {
        this.taocanInfo()
    },
    mounted() { },
    methods: {
        // 获取套餐
        taocanInfo() {
            var that = this
            that.$payApi.rechargeVipList().then(res => {
                if (res.code == 1000) {
                    that.vipObj = res.result.data[0].rights
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 选中
        tabClickBtn(e) {
            var obj = this.tabList[e.index]
            var url = obj.url
            if (obj.name_txt) {
                this.$router.push({
                    path: url,
                    query: {
                        name: obj.name_txt
                    }
                })
            } else if (obj.ids) {
                this.$router.push({
                    path: obj.url,
                    query: {
                        id: obj.ids
                    }
                })
            } else {
                this.$router.push(url)
            }
        },
        // 关闭
        removeTab(val) {
            var arr = JSON.parse(JSON.stringify(this.tabList))
            var index = arr.map(item => item.key).indexOf(val)
            // 删除顶部导航
            arr.splice(index, 1)
            // 储存顶部导航
            this.$store.commit('tabsArr/SET_TABSARR', arr)
            //关闭当前页面
            if (val == this.tabKey) {
                var obj = arr[index - 1]
                if (obj.name_txt) {
                    this.$router.push({
                        path: obj.url,
                        query: {
                            name: obj.name_txt
                        }
                    })
                } else if (obj.ids) {
                    this.$router.push({
                        path: obj.url,
                        query: {
                            id: obj.ids
                        }
                    })
                } else {
                    this.$router.push(obj.url)
                }
            }
        },
        // 立即升级
        upgradeNowBtn() {
            this.$refs.dialogMember.openDialogBtn('header')
        },
        // 跳转
        topNavBtn(url) {
            if (url == '') {
                this.$refs.dialogAcc.openDialogBtn()
            } else {
                this.$router.push(url)
            }
        },
        // 充值回调
        renovateBtn() {
            this.$store.dispatch('userInfo/getUserInfo')
        },
        // 退出登录
        quitBtn() {
            this.$refs.dialogTip.openDialogBtn()
        },
        closeBtn() {
            var that = this
            that.$publicApi.postLogOut().then(res => {
                if (res.code == 1000) {
                    // 退出登录成功
                    localStorage.clear()
                    // 清除token
                    that.$store.commit('DEL_TOKEN', '')
                    // 清除7天免登录
                    that.$store.commit('SET_TIME', '')
                    // 清除用户信息
                    that.$store.commit('userInfo/DEL_USER_INFO', '')
                    // 清除顶部菜单
                    that.$store.commit('tabsArr/SET_TABKEY', '')
                    that.$store.commit('tabsArr/SET_TABSARR', '')
                    // 清除左侧菜单
                    that.$store.commit('asideNav/SET_NAVKEY', '')
                    that.$store.commit('asideNav/SET_NAVTWOKEY', '')
                    // 跳转登录页面
                    that.$router.push('/login')
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        toMessage() {
            this.$router.push({
                path: '/person/message',
            })
        }
    },
}
</script>

<style lang='scss' scoped>
.header_main {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header_left {
        width: calc(100% - 360px);
        height: 100%;
        overflow: hidden;
        /* padding: 4px 0; */
        .header_name{
            display: block;
            width: 100%;
        }
    }

    .header_right {
        height: 100%;
        padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .right_vip {
            cursor: pointer;
            width: 154px;
            height: 30px;

            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }

        .right_icon {
            padding-left: 12px;
            display: flex;
            align-items: center;

            img {
                cursor: pointer;
                display: block;
                width: 36px;
                height: 36px;
                margin-left: 6px;
            }

            .message {
                // margin-top: 10px;
                // font-size: 18px;
                cursor: pointer;


                .img1 {
                    display: block;
                }

                .img2 {
                    display: none;
                }

                &:hover {
                    .img2 {
                        display: block;
                    }

                    .img1 {
                        display: none;
                    }
                }

                ::v-deep .el-badge__content {
                    height: 15px;
                    line-height: 15px;
                    font-size: 12px;
                }
            }
        }

        .right_xian {
            width: 1px;
            height: 15px;
            margin: 0 16px;
            background: rgba(255, 255, 255, 0.5);
        }

        .right_xian_grey {
            background: rgba(212, 212, 212, 0.5);
        }

        .right_header {
            cursor: pointer;
            display: flex;
            align-items: center;

            .avatar_img {
                position: relative;
                width: 30px;
                height: 30px;

                .img_bg {
                    position: absolute;
                    top: -5px;
                    left: -3px;
                    z-index: 1;
                    display: block;
                    width: 36px;
                    height: 36px;
                }
            }
        }
    }
}

.header_main_grey {
    background: #FFFFFF;
}
</style>
<style lang="scss" scoped>
.header_main {
    width: 100%;

    ::v-deep .el-tabs {
        width: 100%;
        height: 48px;

        .el-tabs__header {
            height: 40px;
            border: none;

            .el-tabs__nav-wrap.is-scrollable {
                padding: 0 20px;
            }

            .el-tabs__nav-prev,
            .el-tabs__nav-next {
                height: 48px;
                width: 20px;
                line-height: 48px;
                text-align: center;
            }
        }

        .el-tabs__nav {
            height: 48px;
            // padding: 4px 0;
            border: none;

            .el-tabs__item {
                width: 120px;
                border: none;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                margin: 4px;
                padding: 0 30px 0 12px !important;
                border-radius: 6px;
                background: rgba(255, 255, 255, 0.3);
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline-block;
                justify-content: space-between;

                .el-icon-close {
                    position: absolute;
                    top: 13px;
                    right: 12px;
                }
            }

            .is-active {
                color: #2E4BF2;
                background: rgba(255, 255, 255, 1);
            }
        }
    }
}

.header_main_grey {
    ::v-deep .el-tabs {
        .el-tabs__nav {
            .el-tabs__item {
                background: #F2F2F2;
            }

            .is-active {
                background: rgba(255, 255, 255, 0.8);
                box-shadow: 0px 0px 5px 1px rgba(160, 160, 160, 0.31);
            }
        }
    }
}

//
.vip_main {
    width: 100%;
    height: 422px;
    background: url(~@/assets/images/background/upgrade_background.jpg) no-repeat;
    background-size: 100% 100%;

    .title {
        width: 100%;
        padding: 24px 0 20px;
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
    }

    .vip_text {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        img {
            display: block;
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }

        .text {
            height: 40px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .t_title {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }

            .t_tip {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
            }
        }
    }

    .vip_btn {
        width: 100%;
        margin-top: 4px;
        padding: 0 20px;

        .btnBgColor_blue {
            width: 100%;
        }
    }
}

//
.acatar_main {
    width: 100%;

    .main_info {
        width: 100%;
        height: 50px;
        display: flex;

        .info_img {
            position: relative;
            width: 50px;
            height: 50px;

            .img_bg {
                position: absolute;
                top: -8px;
                left: -5px;
                z-index: 1;
                display: block;
                width: 60px;
                height: 60px;
            }
        }

        .info_txt {
            width: calc(100% - 50px);
            padding-left: 12px;
            height: 50px;
            display: flex;
            justify-content: space-around;
            flex-direction: column;

            .t_name {
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
            }

            .t_phone {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
            }
        }
    }

    .main_vip {
        cursor: pointer;
        width: 100%;
        height: 34px;
        padding: 0 17px;
        margin-top: 22px;
        margin-bottom: 10px;
        background: url(~@/assets/images/background/right_background_btn.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .vip_left {
            display: flex;
            align-items: center;

            img {
                display: block;
                width: 24px;
                height: 24px;
            }

            span {
                padding-left: 6px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
            }
        }

        .vip_right {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;

            .el-icon-arrow-right {
                margin-left: 5px;
            }
        }
    }

    .main_vip_show {
        cursor: pointer;
        position: relative;
        width: 100%;
        height: 90px;
        padding: 0 17px;
        margin-top: 22px;
        margin-bottom: 10px;
        background: url(~@/assets/images/background/right_background_btn_vip.png) no-repeat;
        background-size: 100% 100%;

        .icon {
            position: absolute;
            top: -15px;
            right: 7px;
            z-index: 1;
            display: block;
            width: 66px;
            height: 66px;
        }

        .vip_show_txt {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            padding: 10px 0;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .t_name {
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
            }

            .t_time {
                display: flex;

                .time {
                    padding-right: 8px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #C1CEFF;
                }

                .btn {
                    text-decoration: underline;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #E0E6FF;
                }
            }

            .t_day {
                display: flex;
                justify-content: space-between;

                .time {
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #E4ECFF;
                }

                .btn {
                    margin-right: -5px;
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
    }

    .main_nav {
        cursor: pointer;
        width: 100%;
        height: 45px;
        padding-left: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .nav_left {
            display: flex;
            align-items: center;

            img {
                display: block;
                width: 18px;
                height: 18px;
            }

            span {
                padding-left: 10px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }
        }
    }

    .main_btn {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
            cursor: pointer;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #E51D41;
        }
    }
}
</style>
