
export function formatSeconds(value, type) {
    if(!value){
      return { s: 0 }
    }
    //  秒数转化为时分秒, type=1 天时分秒 2 时分秒 3 obj
    //  秒
    let second = parseInt(value)
    //  分
    let minute = 0
    //  小时
    let hour = 0
    //  天
    let day = 0
    //  如果秒数大于60，将秒数转换成整数
    if (second > 60) {
        //  获取分钟，除以60取整数，得到整数分钟
        minute = parseInt(second / 60)
        //  获取秒数，秒数取佘，得到整数秒数
        second = parseInt(second % 60)
        //  如果分钟大于60，将分钟转换成小时
        if (minute > 60) {
            //  获取小时，获取分钟除以60，得到整数小时
            hour = parseInt(minute / 60)
            //  获取小时后取佘的分，获取分钟除以60取佘的分
            minute = parseInt(minute % 60)
            //  如果小时大于24，将小时转换成天
            if (hour > 23) {
                //  获取天数，获取小时除以24，得到整天数
                day = parseInt(hour / 24)
                //  获取天数后取余的小时，获取小时除以24取余的小时
                hour = parseInt(hour % 24)
            }
        }
    }

    let result = '' + parseInt(second) + '秒'
    if (minute > 0) {
        result = '' + parseInt(minute) + '分' + result
    }
    if (hour > 0) {
        result = '' + parseInt(hour) + '小时' + result
    }


    if (type == 1) {
        if (day > 0) {
            result = '' + parseInt(day) + '天' + result
        }
        return result
    }
    if (type == 2) {
        return result
    }
    if (type == 3) {
        let obj = {
            h: parseInt(hour),
            m: parseInt(minute),
            s: parseInt(second),
        }
        return obj
    }
}
export function timeToMinute(times) {
    var t;
    if (times > -1) {
        var hour = Math.floor(times / 3600);
        var min = Math.floor(times / 60) % 60;
        var sec = times % 60;
        if (hour < 10) {
            // t = "0" + hour + ":";
            t = "";
        } else {
            t = hour + ":";
        }

        if (min < 10) {
            t += "0";
        }
        t += min + ":";
        if (sec < 10) {
            t += "0";
        }
        t += sec.toFixed(2);
    }
    t = t.substring(0, t.length - 3);
    return t;
}
// 获取当前时间
export function currentSaveTime() {
    var time
    var myDate = new Date()
    var h = myDate.getHours()>9?myDate.getHours():'0'+myDate.getHours()
    var m = myDate.getMinutes()>9?myDate.getMinutes():'0'+myDate.getMinutes()
    var s = myDate.getSeconds()>9?myDate.getSeconds():'0'+myDate.getSeconds()
    time = h+':'+m+':'+s
    return time
}

export default {
    formatSeconds,
    timeToMinute,
    currentSaveTime,
}
