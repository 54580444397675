import {arr_list} from '@/utils/role'
import publicApi from '@/axiosApi/public'
const asideNav = {
  namespaced: true,
  state: {// 储存的数据
    navKey:localStorage.getItem('navKey') || '',
    navTwoKey:localStorage.getItem('navTwoKey') || '',
    navList:arr_list,
    roleList:JSON.parse(localStorage.getItem('roleList')) || [],
  },
  mutations: { // 修改
    SET_NAVKEY(state, val) {
     	state.navKey = val
      localStorage.navKey = val
    },
    SET_NAVTWOKEY(state, val) {
     	state.navTwoKey = val
      localStorage.navTwoKey = val
    },
    SET_ROLELIST(state, str) {
     	state.roleList = str
      localStorage.roleList = JSON.stringify(str)
    },
  },
  actions: { // 发起异步请求
    routeMenu({ commit }) {
    	publicApi.routeList().then(res => {
    		if (res.code == 1000) {
    			return commit('SET_ROLELIST', res.result)
    		}
    	})
    },
  },

};
export default asideNav;
