export default {
  // 手机号验证
  isvalidPhone(phone) {
    const reg = /^1(3|4|5|6|7|8|9)\d{9}$/
    return reg.test(phone)
  },
  // 身份证
  isCard(val) {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
    return reg.test(val)
  },
  // 链接
  isUrl(val) {
    const reg = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/
    return reg.test(val)
  },
}
